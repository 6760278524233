// Interpolations
// =================
export const MARKER_START = "{{";
export const MARKER_END = "}}";

// Team: {{ team }} {{ équipe }} {{ equipo }}
export const TEAM_EN = "team";
export const TEAM_FR = "équipe";
export const TEAM_ES = "equipo";

// Candidate first name: {{ candidate_first_name }} {{ candidat_prénom }} {{ candidato_primer_nombre }}
export const CANDIDATE_FIRST_NAME_EN = "candidate_first_name";
export const CANDIDATE_FIRST_NAME_FR = "candidat_prénom";
export const CANDIDATE_FIRST_NAME_ES = "candidato_primer_nombre";

// Candidate last name: {{ candidate_last_name }} {{ candidat_nom_de_famille }} {{ candidato_apellido }}
export const CANDIDATE_LAST_NAME_EN = "candidate_last_name";
export const CANDIDATE_LAST_NAME_FR = "candidat_nom_de_famille";
export const CANDIDATE_LAST_NAME_ES = "candidato_apellido";

// Candidate full name: {{ candidate_full_name }} {{ candidat_nom_complet }} {{ candidato_nombre_completo }}
export const CANDIDATE_FULL_NAME_EN = "candidate_full_name";
export const CANDIDATE_FULL_NAME_FR = "candidat_nom_complet";
export const CANDIDATE_FULL_NAME_ES = "candidato_nombre_completo";
