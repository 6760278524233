import { useEffect, useState } from "react";
import { MARKER_START, MARKER_END } from "../constants/interpolations";


/**
 * A React hook that performs string interpolation by replacing placeholders ({{ key }})
 * with corresponding values from a dictionary.
 * 
 * @param {string} htmlString - The input string containing placeholders to be interpolated
 * @param {Object} dict - Dictionary object containing key-value pairs for interpolation
 * @returns {string} The interpolated string with placeholders replaced by values
 * @throws {Error} If dictionary is undefined or null
 * 
 * @example
 * const template = "Hello {{ full_name }}!";
 * const values = { full_name: "John Doe" };
 * const result = useInterpolation(template, values);
 * // result: "Hello John Doe!"
 */
export const useInterpolation = (htmlString, dict) => {
  const [interpolatedString, setInterpolatedString] = useState(htmlString);
  
  if(typeof dict === 'undefined' || dict === null){
    throw new Error("No dictionary found", dict);
  }

  useEffect(() => {
    let result = htmlString;
    
    // For each key in the dictionary
    Object.keys(dict).forEach(key => {
      // Create a regex that matches {{ key }} with optional spaces
      const regex = new RegExp(`${MARKER_START}\\s*${key}\\s*${MARKER_END}`, 'g');
      // Replace only if the value exists and is not undefined/null
      const value = dict[key];
      // If the value is undefined/null, replace the placeholder with the original string
      result = result.replace(regex, value != null ? value : `${MARKER_START} ${key} ${MARKER_END}`);
    });

    setInterpolatedString(result);
  }, [htmlString, dict]); // Re-run when htmlString or dict changes

  return interpolatedString;
};