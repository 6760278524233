import React, { useState, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import DatePickerSelect from "../helpers/date-picker-select";
import { useInterpolation } from "../../hooks/useInterpolation";
import { TEAM_EN, TEAM_FR, TEAM_ES } from "../../constants/interpolations";
import { CANDIDATE_FIRST_NAME_EN, CANDIDATE_FIRST_NAME_FR, CANDIDATE_FIRST_NAME_ES } from "../../constants/interpolations";
import { CANDIDATE_LAST_NAME_EN, CANDIDATE_LAST_NAME_FR, CANDIDATE_LAST_NAME_ES } from "../../constants/interpolations";
import { CANDIDATE_FULL_NAME_EN, CANDIDATE_FULL_NAME_FR, CANDIDATE_FULL_NAME_ES } from "../../constants/interpolations";

function LayoutAcademic10({
  candidate,
  assesment,
  handleInputChange,
  handleDateChange,
  sex,
  lang,
  submit,
  agreed,
  readNunderstood,
  cancel,
  children
}) {
  const rawHtml = useMemo(() => {
    return assesment?.evaluation_session?.consent?.text?.[lang.current_lang] || '';
  }, [assesment, lang.current_lang]);

  const interpolatedHtml = useInterpolation(rawHtml, {
    [TEAM_EN]: assesment?.evaluation_session?.team?.name?.[lang.current_lang],
    [TEAM_FR]: assesment?.evaluation_session?.team?.name?.[lang.current_lang],
    [TEAM_ES]: assesment?.evaluation_session?.team?.name?.[lang.current_lang],
    [CANDIDATE_FIRST_NAME_EN]: candidate?.first_name,
    [CANDIDATE_FIRST_NAME_FR]: candidate?.first_name,
    [CANDIDATE_FIRST_NAME_ES]: candidate?.first_name,
    [CANDIDATE_LAST_NAME_EN]: candidate?.last_name,
    [CANDIDATE_LAST_NAME_FR]: candidate?.last_name,
    [CANDIDATE_LAST_NAME_ES]: candidate?.last_name,
    [CANDIDATE_FULL_NAME_EN]: candidate?.name,
    [CANDIDATE_FULL_NAME_FR]: candidate?.name,
    [CANDIDATE_FULL_NAME_ES]: candidate?.name,
  });

  return (
    <div id="consent" className="page">
      <section>
        <div id="consent-content">
          <FormattedMessage
            id="consent.academic-10.title"
            defaultMessage="Autorisation à l'évaluation"
          >
            {(txt) => <h1>{txt}</h1>}
          </FormattedMessage>
          <FormattedMessage
            id="consent.academic-10.intro"
            defaultMessage=""
          >
            {(txt) => <p>{txt}</p>}
          </FormattedMessage>
          <form className="form-vertical">
            <fieldset>
              <FormattedMessage
                id="consent.academic-10.title.form"
                defaultMessage=""
              >
                {(txt) => <h3>{txt}</h3>}
              </FormattedMessage>
              <FormattedMessage
                id="app.global.label.name"
                defaultMessage=""
              >
                {(txt) => (
                  <label>
                    {txt}
                    <FormattedMessage id="app.global.semi-colon" />
                  </label>
                )}
              </FormattedMessage>
              <div className="input-row">
                <input
                  type="text"
                  value={candidate.first_name}
                  disabled={true}
                />
                <input type="text" value={candidate.last_name} disabled={true} />
              </div>
              <FormattedMessage
                id="app.global.label.gender"
                defaultMessage=""
              >
                {(txt) => (
                  <label>
                    {txt}
                    <FormattedMessage id="app.global.semi-colon" />
                  </label>
                )}
              </FormattedMessage>
              <select
                name="sex"
                id="sex"
                onChange={handleInputChange}
                value={sex}
              >
                <option value="">--</option>
                <FormattedMessage id="app.global.label.female">
                  {(txt) => <option value="F">{txt}</option>}
                </FormattedMessage>
                <FormattedMessage id="app.global.label.male">
                  {(txt) => <option value="M">{txt}</option>}
                </FormattedMessage>
                <FormattedMessage id="app.global.label.other">
                  {(txt) => <option value="O">{txt}</option>}
                </FormattedMessage>
              </select>
              <FormattedMessage id="app.global.label.dob" defaultMessage="">
                {(txt) => (
                  <label>
                    {txt}
                    <FormattedMessage id="app.global.semi-colon" />
                  </label>
                )}
              </FormattedMessage>
              <DatePickerSelect
                id="dob_select"
                date={candidate.dob}
                onChange={handleDateChange}
              />
            </fieldset>
          </form>
          
          <div dangerouslySetInnerHTML={{ __html: interpolatedHtml }} />

          <ul className="no-bullet vertical">
            <li className="text-center">
              <label
                id="agreement_chkb-label"
                htmlFor="agreement_chkb"
                className="inline"
              >
                <input
                  id="agreement_chkb"
                  name="agreed"
                  value="true"
                  type="checkbox"
                  checked={agreed}
                  onChange={handleInputChange}
                />
                <FormattedMessage id="consent.academic-10.label.I-accept" />
              </label>
            </li>
            <li className="text-center">
              <label
                id="readNunderstood_chkb-label"
                htmlFor="readNunderstood_chkb"
                className="inline"
              >
                <input
                  id="readNunderstood_chkb"
                  name="readNunderstood"
                  value="true"
                  type="checkbox"
                  checked={readNunderstood}
                  onChange={handleInputChange}
                />
                <FormattedMessage
                  id="consent.academic-10.label.I-read-understood"
                  values={{
                    link: <a href={process.env.PUBLIC_URL + `/files/terms_${lang.current_lang ?? "en"}.html`} target="_blank"><FormattedMessage id="consent.academic-10.user-agreements.title" /></a>
                  }}
                />
              </label>
            </li>
            <li id="ok-btn-wrapper" className="text-center">
              <button
                className="btn btn-primary"
                onClick={submit}
                disabled={!agreed || !readNunderstood}
              >
                <FormattedMessage id="app.global.button.continue" />
              </button>
            </li>
            <li id="cancel-btn-wrapper" className="text-center">
              <button className="btn btn-link" onClick={cancel}>
                <FormattedMessage id="app.global.button.cancel" />
              </button>
            </li>
          </ul>
        </div>
      </section>
      {children}
    </div>
  );
}

export default LayoutAcademic10;
